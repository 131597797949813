import { Link } from "@remix-run/react"

export function Footer() {
  return (
    <footer className="bg-black text-white py-12 md:py-16">
      <div className="container px-4 md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-300 hover:text-white transition-colors">
                  Best of Me
                </Link>
              </li>
              <li>
                <Link to="/our-promises" className="text-gray-300 hover:text-white transition-colors">
                  Our Promises
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-300 hover:text-white transition-colors">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-300 hover:text-white transition-colors">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>

          {/* Support Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/help" className="text-gray-300 hover:text-white transition-colors">
                  Help Center
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-300 hover:text-white transition-colors">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/feedback" className="text-gray-300 hover:text-white transition-colors">
                  Feedback
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal & Compliance Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal & Compliance</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/security" className="text-gray-300 hover:text-white transition-colors">
                  Data Security
                </Link>
              </li>
              <li>
                <Link to="/gdpr" className="text-gray-300 hover:text-white transition-colors">
                  GDPR Compliance
                </Link>
              </li>
              <li>
                <Link to="/accessibility" className="text-gray-300 hover:text-white transition-colors">
                  Accessibility Statement
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800">
          <p className="text-center text-gray-400">
            © {new Date().getFullYear()} Best of Me. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}