import { Link } from "@remix-run/react"
import { Logo } from "../layout/Logo"


const Header = ({ timeZone }: { timeZone: string }) => {


  return (
    <header className="container pt-4 pb-4 pl-4 pr-4 text-gray-600 bg-white">
      <nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
        <Logo timeZone={timeZone}/>
        <div className="flex items-center gap-5 font-semibold">
          <Link to="/login">Sign in</Link>
        </div>
      </nav>
    </header>
  )
}

export default Header