import {Links, Meta, Scripts, ScrollRestoration} from '@remix-run/react'
import React from 'react'
import {ClientHintCheck} from '#app/utils/client-hints'
import type {Theme} from '#app/utils/theme.server'

interface DocumentProps {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}

/**
 * Document component that provides the base HTML structure for the application
 * 
 * This component handles:
 * - Basic HTML document structure
 * - Theme application
 * - Meta tags and SEO settings
 * - Client hints for browser capabilities
 * - Environment variable injection
 * - Core styles and animations
 * 
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render within the document
 * @param {string} props.nonce - Unique nonce for CSP
 * @param {Theme} [props.theme='light'] - Current theme setting ('light' | 'dark' | 'system')
 * @param {Record<string,string>} [props.env={}] - Environment variables to expose to client
 * @param {boolean} [props.allowIndexing=true] - Whether to allow search engine indexing
 * @returns {JSX.Element} The complete HTML document structure
 */
export function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
	allowIndexing = true,
}: DocumentProps) {
	return (
		<html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
				<style>{`
					@keyframes blob {
						0%, 100% { transform: translate(0, 0) scale(1); }
						25% { transform: translate(20px, -30px) scale(1.1); }
						50% { transform: translate(-20px, 20px) scale(0.9); }
						75% { transform: translate(30px, 30px) scale(1.05); }
					}

					.animate-blob {
						animation: blob 7s infinite;
					}

					.animation-delay-2000 {
						animation-delay: 2s;
					}

					.animation-delay-4000 {
						animation-delay: 4s;
					}

					.blob-shape {
						border: 2px solid red;
					}
				`}</style>
			</head>
			<body className="text-foreground">
				<div className="fixed inset-0 z-[-1] bg-gradient-to-b from-yellow-50 via-white to-yellow-50 bg-[length:100%_1000px] bg-repeat-y"></div>
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
} 