import {parseWithZod} from '@conform-to/zod'
import {invariantResponse} from '@epic-web/invariant'
import {useLoaderData, useMatches, Link, Outlet} from '@remix-run/react'
import {withSentry} from '@sentry/remix'
import {
	type ActionFunctionArgs,
	type HeadersFunction,
	json,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@vercel/remix'
import React from 'react'
import {HoneypotProvider} from 'remix-utils/honeypot/react'
import {z} from 'zod'
import {dasboardRouteToday} from "./common/routes.ts"
import {GeneralErrorBoundary} from './components/error-boundary.tsx'
import {FooterButton} from "./components/FooterButton.tsx"
import {Document} from './components/layout/Document'
import {Logo} from './components/layout/Logo'
import {UserDropdown} from './components/navigation/UserDropdown'
import {NonLoggedInLayout} from './components/non-logged-in-layout.tsx'
import {EpicProgress} from './components/progress-bar.tsx'
import {SearchBar} from './components/search-bar.tsx'
import {useToast} from './components/toaster.tsx'
import {href as iconsHref} from './components/ui/icon.tsx'
import {EpicToaster} from './components/ui/sonner.tsx'
import * as UserRepository from './domain/user/user-repository.ts'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import {getUserId, logout} from './utils/auth.server.ts'
import {getHints, useHints} from './utils/client-hints.tsx'
import {getEnv} from './utils/env.server.ts'
import {honeypot} from './utils/honeypot.server.ts'
import {combineHeaders, getDomainUrl} from './utils/misc.tsx'
import {useNonce} from './utils/nonce-provider.ts'
import {ThemeFormSchema, useTheme} from './utils/theme'
import { getTheme, setTheme } from './utils/theme.server.ts'
import {makeTimings, time} from './utils/timing.server.ts'
import {getToast} from './utils/toast.server.ts'
import {useOptionalUser} from './utils/user.ts'
import { LandingPage } from './components/landing-page.tsx'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		// Preload CSS as a resource to avoid render blocking
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'best of me' : 'Error | best of me' },
		{ name: 'description', content: `Your own log` },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					UserRepository.getByIdOrThrow(userId),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
			beta: process.env.BETA === undefined ? true : process.env.BETA === 'true'
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}
	return json({ result: submission.reply() }, responseInit)
}

/**
 * Main application component that handles the overall layout and routing
 * 
 * Renders different layouts based on user authentication status:
 * - For authenticated users: Shows header with logo, search bar, user dropdown and main content
 * - For non-authenticated users: Shows non-logged in layout
 * 
 * Also handles theme preferences, toasts notifications and progress indicators
 * 
 * @returns {JSX.Element} The rendered application UI
 */
function App() {
	const data = useLoaderData<typeof loader>()
	const { beta } = data
	const nonce = useNonce()
	const user = useOptionalUser()
	const theme = useTheme()

	console.log('theme', theme)

	const matches = useMatches()
	const { timeZone } = useHints()

	const isRoot = matches.find(m => {
		return [
			'routes/_marketing+/index',
		].includes(m.id)
	})

	const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'
	useToast(data.toast)

	return (
		<Document
			nonce={nonce}
			theme={theme}
			allowIndexing={allowIndexing}
			env={data.ENV}
		>
			{user ? (
				<div className="flex flex-col min-h-screen">
					<section className="flex-grow relative bg-gradient-to-b from-yellow-50 to-white">
						{isRoot && (
							<div className="absolute inset-0 w-full h-full">
								<img
									src="/img/bg.png"
									alt=""
									className="w-full h-full object-cover"
									style={{
										objectPosition: 'center top'
									}}
								/>
							</div>
						)}
						<header className="container relative pt-4 pb-4 pl-4 pr-4 text-gray-600">
							<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
								<Logo userName={user?.username} timeZone={timeZone}/>
								<div className="ml-auto hidden max-w-sm flex-1 sm:block">
									{!isRoot && <SearchBar status="idle" />}
								</div>
								<div className="flex items-center gap-10">
									<UserDropdown/>
								</div>
								<div className="block w-full sm:hidden">
									{!isRoot && <SearchBar status="idle" />}
								</div>
							</nav>
						</header>

						<main className="font-quicksand flex flex-col items-center justify-center pb-20 relative z-10">
							<Outlet/>
						</main>
					</section>
					{!beta && (
						<footer className="fixed bottom-0 left-0 right-0 z-50">
							<div className="w-full bg-muted shadow-lg flex justify-around items-center p-4">
								<Link 
									to={dasboardRouteToday(user.username, timeZone)}
									className="w-1/2 flex justify-center"
								>
									<FooterButton label="Dashboard" tooltip="Dashboard" iconName="list-bullet" />
								</Link>
								<Link 
									to={`/dashboard/${user.username}/reflect`}
									className="w-1/2 flex justify-center"
								>
									<FooterButton label="Add" tooltip="Add Challenging Situation" iconName="plus" />
								</Link>
							</div>
						</footer>
					)}
				</div>
			) : (
				<NonLoggedInLayout />
        // <LandingPage />
			)}
			<EpicToaster closeButton position="top-center" theme={theme}/>
			<EpicProgress/>
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App/>
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	const nonce = useNonce()
	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
