import {Form,useSubmit} from '@remix-run/react'
import {useRef} from 'react'
import {dasboardRouteToday} from "#app/common/routes"
import {Button} from '#app/components/ui/button'
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuPortal, DropdownMenuTrigger} from '#app/components/ui/dropdown-menu'
import {Icon} from '#app/components/ui/icon'
import {userDisplayName} from "#app/domain/user/user-model"
import {useHints} from '#app/utils/client-hints'
import {getUserImgSrc} from '#app/utils/misc.tsx'
import {useUser} from '#app/utils/user'
import {MenuItem} from './MenuItem'

export function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	const displayName = userDisplayName(user)
	const { timeZone } = useHints()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" className="bg-blue-400 hover:bg-blue-500 text-white border-none shadow-lg">
					<div className="flex items-center gap-2">
						<img
							className="h-8 w-8 rounded-full object-cover"
							alt={displayName}
							src={getUserImgSrc(user.image?.id)}
						/>
						<span className="text-body-sm font-bold">
							{displayName}
						</span>
					</div>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent 
					sideOffset={8} 
					align="end" 
					className="bg-white p-2 shadow-lg border border-gray-200 text-gray-800"
				>
					<MenuItem 
						to={dasboardRouteToday(user.username, timeZone)} 
						label="Dashboard" 
						icon="home" 
					/>
					<MenuItem 
						to={`/users/${user.username}`} 
						label="Profile" 
						icon="avatar" 
					/>
					<DropdownMenuItem
						asChild
						onSelect={event => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<button type="submit" className="w-full text-left flex items-center hover:bg-blue-500 hover:text-white px-2 py-1 rounded">
								<Icon name="exit" className="mr-2" />
								Logout
							</button>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
} 