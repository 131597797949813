import {Link} from '@remix-run/react'
import {dasboardRouteToday} from "#app/common/routes.ts"

interface LogoProps {
	userName?: string
	timeZone: string
}

export const Logo = ({userName, timeZone}: LogoProps) => {
	return (
		<Link to={userName ? dasboardRouteToday(userName, timeZone) : "/"} className="group grid leading-snug">
			<div className="flex items-center space-x-1">
				<img src="/img/best-of-me-heart-transparent-smaller.webp" className="w-16 h-10" />
				<span className="font-bold transition group-hover:-translate-x-1">
					best of me
				</span>
			</div>
		</Link>
	)
} 