import {Link} from '@remix-run/react'
import {DropdownMenuItem} from '#app/components/ui/dropdown-menu'
import {Icon, type IconName} from '#app/components/ui/icon'

interface MenuItemProps {
	label: string
	to: string
	icon: IconName
}

export const MenuItem = ({ label, to, icon }: MenuItemProps) => (
	<DropdownMenuItem asChild>
		<Link to={to} className="flex items-center px-2 py-1 hover:bg-blue-500 hover:text-white rounded transition-colors duration-200">
			<Icon name={icon} className="mr-2" />
			{label}
		</Link>
	</DropdownMenuItem>
) 