import { Outlet } from '@remix-run/react';
import React from 'react';
import Header from '#app/components/common/Header';
import { useHints } from '#app/utils/client-hints.js';
import { Footer } from './landing/Footer';

export function NonLoggedInLayout() {
  const timeZone = useHints();

  return (
    <>
      <main className="container flex flex-col justify-center bg-gradient-to-b from-yellow-50 to-white">
        <div className="min-h-screen bg-white">
          <Header timeZone={timeZone} />
          <Outlet />
        </div>
      </main>
      <div className="container bg-black">
        <Footer />
      </div>
    </>
  );
}